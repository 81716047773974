<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title class="ion-text-center">
          <img src="assets/icon/logo.png" style="height:40px;" alt="xtenso|xsample">
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content  class="ion-padding">
      <h2>Aide</h2>
      <p>XSample est une application destinée à réaliser des échantillonnages des activités d’une ou de plusieurs ressources (machines, ressources humaines).</p>
      <p>Si les échantillonnages sont réalisés avec rigueur (en nombre suffisant, dans une période de temps significative, à des intervalles de temps réguliers, etc.), il est possible d’extrapoler un mix d’activités pour la ou les ressource(s).</p>
      <p>Sur la base des échantillonnages, trois types d’informations peuvent être obtenus :</p>
        <ol>
          <li>La répartition de l’occurrence des activités par intervalle de temps (jours, semaine, mois, etc.) et par ressource ou groupe de ressources.</li>
          <li>Le temps d’inter-arrivée d’une ou plusieurs activités par ressource ou groupe de ressources.</li>
          <li>La répartition des occurrences dans un intervalle de temps donné, pour une ou plusieurs ressources, d’une activité ou d’un groupe d’activités.</li>
        </ol>
      <p>Tous ces calculs sont faits à l’extérieur de XSample sur la base des logs effectués et envoyés par XSample sur une adresse email ou en procédant à une extraction sur le serveur sous condition d’un droit d’accès disponible.</p>

      <h3 class="ion-padding-top">Exploitation de XSample</h3>

      <p>L’exploitation de XSample comporte les phases suivantes :
      <ol>
        <li>Paramétrage
          <ol>
            <li>Des ressources</li>
            <li>Des activités par ressource</li>
          </ol></li>
        <li>Logs des activités par ressource</li>
        <li>Envoi des logs / accès au serveur</li>
        <li>Etablissement des statistiques (hors XSample)</li>
      </ol>
      </p>

      <h3 class="ion-padding-top">Paramétrage</h3>
      <p>Le paramétrage des ressources se fait à partir du menu à gauche (ajouter une ressource). Il est important que chaque ressource possède un nom unique. Une fois créés, les ressources peuvent être triées.</p>

      <p>Une fois la ressource créé, il est possible de définir les activités de celle-ci. Chaque activité est de trois types : A valeur ajoutée, Gaspillage utile, Gaspillage inutile.</p>
      <p>Une fois crées les activités peuvent être triées.</p>

      <h3 class="ion-padding-top">Logs des activités</h3>
      <p>Le log des activités se fait en se positionnant sur l’écran de log. Menu modifier.</p>
      <p>A partir du plan d’échantillonnage établi par l’utilisateur (heures d’échantillonnage), une tournée est organisée pour identifier l’activité dans laquelle se trouve chaque ressource.</p>
      <p>Le plan d’échantillonnage doit correspondre à la séquence des ressources, afin que XSample puisse passer d’une ressource à la suivante automatiquement lorsque l’activité d’une ressource a été logée.</p>

      <h3 class="ion-padding-top">Envoi des logs</h3>
      <p>L’envoi des logs se fait en accédant au menu principal. Les logs sont envoyés sur l’adresse email de l’utilisateur principal.</p>
      <h3 class="ion-padding-top">Proposé par</h3>
      <p class="ion-text-center">
        <a href="http://xtenso.com/" target="about:blank">
        <img class="logo" src="assets/icon/logo_xtenso.png" alt="logo"  style="max-width:80%" >
          </a>
      </p>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";

import { defineComponent } from "vue";
export default defineComponent({
  name: "Help",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
  },
});
</script>
